import { useEffect, useState, useRef, useContext, Suspense } from 'react';
import { useTranslation } from "react-i18next";
import storageSession from '../util/storage';
import api from '../service/api.ts';

import './google.css';
import './flag-icons.css';
import { parseJSON } from '../util';
import { GlobalDispatchContext } from '../context/global';

const getLangs = async () => {
  const resp = await api.getLangs();
  return resp?.data;
};

const postLang = async (lng) => {
  const resp = await api.postLang(lng);
  return resp?.data;
};

const TranslateInternal = ({ isLogon = false}) => {
  const langListInit = {
    pl: 'polski',
    en: 'angielski',
    // de: 'niemiecki',
    // fr: 'francuski',
  };

  const globalContext = useContext(GlobalDispatchContext);
  const { i18n, t } = useTranslation('lang');
  const [showList, setShowList] = useState(false);
  const [langList, setLangList] = useState(langListInit);
  const [loading, setLoading] = useState(false);
  const refList = useRef();
  let isMounted = true;

  const onBlurList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (isMounted && refList.current && !refList.current.contains(document.activeElement)) {
        setShowList(false);
      }
    }, 100);
  };

  useEffect(() => {
    isMounted = true;

    if (isLogon == true) {
      const lang = i18n.language != "pl" && i18n.language != "" ? "en" : "pl";
      i18n.changeLanguage(lang);
      globalContext.setLang(lang);
      return;
    }

    setLoading(true);
    getLangs().then(data => {
      if (data?.status != 0) return;

      const dataLang = parseJSON(data.dane);
      if (!dataLang || dataLang.length == 0) return;

      const langListTmp = dataLang
        .reduce((prev, curr) => (
          { ...prev, [String(curr.symbol_lng).toLowerCase()] : String(curr.nazwa_lng).toLowerCase()}
        ), {});

      // setLangList({... langListTmp, ...langListInit});
      if (isMounted == false) return;
      setLangList(langListTmp);
    }).finally(() => {
      isMounted && setLoading(false)
    });

    return () => {
      isMounted = false;
    }
  }, []);

  const onLanguageSelect = (lang) => {
    setShowList(false);

    if (document.documentElement.lang === lang) return;

    if (isLogon) {
      i18n.changeLanguage(lang);
      globalContext.setLang(lang);
      return;
    }

    setLoading(true);
    postLang(lang).then(data => {
      if (data?.status != 0) return;
      // console.log("setLang", data, lang);
      if (isMounted == false) return;

      i18n.changeLanguage(lang);
      globalContext.setLang(lang);
    }).finally(() => isMounted && setLoading(false));
  };

  if (langList.length == 0) return '';

  return (
    <Suspense loading="">
      <div className="flex flex-end" id="b2b_sitetranslate_main" data-test="internal">
        <div className="relative inline-block text-left">
          <div>
            <button
              onClick={() => setShowList(!showList)}
              onBlur={onBlurList}
              type="button"
              className="flex flex-row justify-center gap-1 text-primary"
            >
              <span className={`flex h-5 w-7 ${loading ? 'img-load' : 'border border-transparent border-px'}`}>
                <i className="h-4 w-7 fflag fflag-html" />
              </span>
              {/* <span>{langList[chooseLang]}</span> */}
              <svg className="w-4 h-4 relative top-0.5" viewBox="0 0 24 24" fill="none">
                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div
            tabIndex={-1}
            ref={refList}
            onBlur={onBlurList}
            className={`${isLogon ? "origin-topright right-0": "origin-top-left left-0"} absolute z-20 top-5 transition-opacity 
              ${showList ? 'opacity-100 visible' : 'invisible opacity-0'}`}
            // style={{ minWidth: '140px' }}
          >
            <div className="relative right-0 block mt-1 whitespace-no-wrap bg-white rounded-md shadow-lg outline-none ring-1 ring-black ring-opacity-5">
              <ul
                tabIndex={0}
                role="listbox"
                aria-labelledby="language-label"
                aria-activedescendant="language-0"
                className="py-1 text-sm focus:outline-none sm:text-sm"
              >
                <li className="px-3 py-2 text-xs text-gray-500 border-b border-gray-300 border-dashed">{t('Wybierz\u00A0język')}</li>
                {[...Object.entries(langList)]
                  .map(([key, value]) => (
                    <li
                      key={`google_language_choose_${key}`}
                      id={`google_language-${key}`}
                      role="option"
                      aria-selected="false"
                      className={`group relative py-0 cursor-default select-none hover:text-white 
                        hover:bg-primary ${key == storageSession.get("global-lang") ? 'bg-primary text-white' : 'text-gray-700'}`}
                    >
                      <button
                        type="button"
                        onMouseDown={(e) => onLanguageSelect(key)}
                        className="py-2.5 pl-3 pr-6 outline-none"
                      >
                        <div className="flex flex-row items-center gap-2" title={value}>
                          <div className={`flags fflag fflag-${key.toUpperCase()}`} />
                          <div className="text-sm lowercase whitespace-nowrap">
                            {t(value)}
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default TranslateInternal;
