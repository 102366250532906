import { useState, useContext, useEffect } from 'react';
import Link from '../router/link';
import { GlobalStateContext, GlobalDispatchContext } from '../context/global';

import MobileBtn from './mobilebtn';
import Search from './search';
import SearchButton from './searchbutton';
import BigCart from './bigcart';
import Logout from './logout';

import Guardian from './guardian';
// import ChatSocket from '../chat';
import UserProfile from './UserProfile';
import PatternBuy from './patternbuy';
import AlertMessage from './AlertMessage';
import UserHan from './UserHan';
import UserBranch from './UserBranch';
import UserCredit from './UserCredit';
import TranslateGoogle from '../translate/TranslateGoogle';
import TranslateInternal from '../translate/TranslateInternal';
import WindowZoom from '../WindowZoom';

import useWindowSize from '../hook/useWindowSize';

import storageSession from '../util/storage';
import styled from 'styled-components';

import useFileCache from '../hook/useFileCache';
import LazyImage from '../hook/lazyimage';
import { useTranslation } from 'react-i18next';

const DivSearch = styled.div`
  transition: all 0.2s ease-in-out;
  visibility:hidden;
  opacity:0;
  transform: translateX(-50px);

  &.toggle {
    visibility:visible;
    opacity:1;
    transition: all 0.2s ease-in-out;
    transform: translateX(0);
  }

  @media (min-width: 1024px) {
    visibility:visible;
    opacity:1;
    transform: translateX(0);
  }
`;

export default function Nav() {
  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const { t } = useTranslation('home');

  const [pathName, setPathName] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [clientWidth] = useWindowSize();

  const [,,,loadLogoImage] = useFileCache();


  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  const searchShow = /\/article|\/cart/.test(pathName);
  const searchMode = /\/article/.test(pathName) ? 0 : 1;

  return (
    <>
      {/* <WindowZoom /> */}
      <nav className="bg-gray-100">
        <div className="px-4 mx-auto max-w-8xl sm:px-6">
          <div className="flex items-center justify-between h-8 gap-3 text-xs text-gray-800">
            <div>
              <AlertMessage />
            </div>
            {!!globalState.user?.is_han == false && (
              <Guardian />
            )}
            {!!globalState.user?.trans_enable && globalState.user?.trans_enable == 2 && <TranslateGoogle />}
            {!!globalState.user?.trans_enable && globalState.user?.trans_enable == 3 && <TranslateInternal />}
            {!!globalState.user?.is_han && <UserHan id={globalState.user?.is_han} />}
            {!!globalState.user?.branch_show && <UserBranch />}
            {/*
            <!-- for development -->
             {process.env.NODE_ENV === 'development' && (
              <div className="">
                <ChatSocket />
              </div>
            )}
            */}
            <div className="flex-grow">&nbsp;</div>
            <UserCredit />
            {/*
            <div className="hidden px-3 lg:block">
              <span className="text-gray-600">Przeterminowane:</span>
              &nbsp;
              <span className="text-red-600">{globalState.user.credit_minus || 0} zł</span>
            </div>
            */}
            {typeof globalState.user == "object" && (
              <>
                {globalState.user.company && (
                  <div className="hidden lg:block">
                    <span translate='no' data-cy="company-name">{globalState.user.company}</span>
                  </div>
                )}
                <UserProfile user={globalState.user} />
              </>
            )}
            {/* <Logout /> */}
          </div>
        </div>
      </nav>

      <nav className="relative bg-white">
        <span className="shadowround" />

        <div className="relative z-10 px-4 mx-auto bg-white max-w-8xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-start h-16">
            <MobileBtn isOpen={false} setIsOpen={(on) => globalDispatch.setMobile(on)} />

            <div className="flex items-center">
              <div className="flex-shrink-0 hidden p-4 lg:pl-0 md:flex">
                <Link to="/home" data-cy="company-logo">
                  {typeof globalState.user != "object" || ("" + globalState.user?.profile) == "" && (
                    <img className="h-8" src="/plugins/logo.png" data-cy="company-logo" alt="Humansoft logo" /> 
                  )}
                  {typeof globalState.user == "object" && globalState.user?.profile != "" && (
                    <LazyImage
                      className="h-8"
                      src="/plugins/logo.png"
                      symbol={globalState.user.profile}
                      width=""
                      height="40"
                      alt="Humansoft logo"
                      handlerLoad={(symbol) => loadLogoImage(symbol)}
                    />
                  )}
                </Link>
              </div>
              <div className={`${globalState.user?.modules[1] == 1 && globalState.user?.modules[0] != 1 ? '' : 'hidden'} md:block`}>
                <div className="flex items-center mx-4 space-x-4">
                  {/* <Link to="/login" className={`${pathname === '/login' ? 'active' : ''} px-3 py-2 text-base text-center font-medium text-gray-700 hover:text-primary`}>Logowanie</Link> */}
                  {typeof globalState.user?.modules == 'object' && globalState.user?.modules[0] == 1 && (
                    <Link to={`/article${globalState.category.length > 0 ? '#'+globalState.category : ''}`} className={`${pathName.includes('/article') ? 'active' : ''} px-3 py-2 text-base text-center font-medium hover:text-primary `}>
                      {/* <span className="text-[#ed19d1]">Zakupy</span> */}
                      <span>{t('Zakupy')}</span>
                    </Link>
                  )}
                  {typeof globalState.user?.modules == 'object' && globalState.user?.modules[1] == 1 && (
                    <Link to="/client" data-cy="client-zone-button" className={`${pathName.includes('/client') ? 'active' : ''} px-3 py-2 text-base text-center font-medium text-gray-700 hover:text-primary`}>
                      {t('Strefa klienta')}
                    </Link>
                  )}
                  {/* <Link to="/claim" className={`${pathName === '/claim' ? 'active' : ''} px-3 py-2 text-base text-center font-medium text-gray-700 hover:text-primary`}>Reklamacje</Link> */}
                  {/* <Link to="/document" className={`${pathName === '/document' ? 'active' : ''} px-3 py-2 text-base text-center font-medium text-gray-700 hover:text-primary`}>Dokumenty</Link> */}
                </div>
              </div>
            </div>

            

            {/* <div className="justify-center flex-grow hidden lg:flex">
              <div className={`relative w-full px-2 text-base text-gray-600 transition-colors duration-150 bg-accents-1 flex items-center`}>
                <Search mode={searchMode} />
              </div>
            </div> */}
            {typeof globalState.user?.modules == 'object' && globalState.user?.modules[0] == 1 && (
              <>
                {searchShow && (
                  <div className="flex justify-center flex-grow "> 
                    <div className="flex items-center lg:hidden">
                      <SearchButton handleUpdate={(isShow) => setToggleSearch(isShow) } />
                    </div>
                    <div className='relative flex items-center w-full px-2 text-base text-gray-600 transition-colors duration-150 bg-accents-1'>
                      <DivSearch className={`${toggleSearch ? 'toggle' : ''} absolute bg-white z-10 left-0 w-56 lg:w-auto`}>
                        <Search mode={(clientWidth >= 1200 && searchMode == 0) ? 0 : 1} />
                      </DivSearch>
                    </div>
                  </div>
                )}

                <div className={`flex justify-center flex-grow ${searchShow ? 'hidden' : ''}`} />

                <div className="block">
                  <div className="relative flex items-center ">
                    <PatternBuy />
                  </div>
                </div>

                <div className="block">
                  <div className="relative flex items-center ">
                    <BigCart />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
