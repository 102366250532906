import { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { debounce } from '../../components/util/index';

const DivGrow = styled.div`
  display: grid;

  &::after {
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    word-break: break-all;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
  }

  & > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::after {
    padding: 0.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;

const Note = ({ text = '', updateHandler = (data) => {}, onSave = (save) => {} }) => {
  const { t } = useTranslation('cart');
  const [showNote, setShowNote] = useState(false);
  const [content, setContent] = useState(text);
  const refNote = useRef();
  const maxLength = 254;

  const onTextareaGrow = useCallback( // for non multi
    debounce(() => {
      // e.preventDefault();
      const note = refNote.current.value.substring(0, maxLength);
      refNote.current.parentNode.dataset.value = note;
    }, 50),
    [],
  );

  const onSaveNote = async () => {
    const note = refNote.current.value;
    onSave(false);

    if (note == content) {
      onSave(false);
      setShowNote(false);
      return;
    }

    const noteParsed = String(note).replaceAll("\n", " ").substring(0, maxLength);
    const result = await updateHandler(noteParsed);

    if (result) {
      setContent(note);
    }

    onSave(true);
    setShowNote(false);
  };

  const onEditNote = (e) => {
    const isShowNote = !showNote;
    setShowNote(isShowNote);
    onSave(false);

    if (isShowNote && refNote.current) {
      setTimeout(() => refNote.current.focus(), 0);
      onTextareaGrow();
    } else {
      onSave(true);
    }
  };

  const onDiscardNote = () => {
    setShowNote(false);
    setContent(content);
    onSave(true);
    refNote.current.value = content;
  };

  const onClearNote = () => {
    // setContent("");
    refNote.current.value = '';
  };

  return (
    <>
      <button
        type="button"
        className="block text-center group focus:outline-none md:text-left"
        onClick={onEditNote}
      >
        <svg className="inline w-3 h-3 group-hover:text-primary" viewBox="0 0 512 512" fill="currentColor">
          <path d="M352.459,220c0-11.046-8.954-20-20-20h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206
            C343.505,240,352.459,231.046,352.459,220z"
          />
          <path d="M126.459,280c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H251.57c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20
            H126.459z"
          />
          <path d="M173.459,472H106.57c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h245.889c22.056,0,40,17.944,40,40v123
            c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112,0-80,35.888-80,80v352
            c0,44.112,35.888,80,80,80h66.889c11.046,0,20-8.954,20-20C193.459,480.954,184.505,472,173.459,472z"
          />
          <path d="M467.884,289.572c-23.394-23.394-61.458-23.395-84.837-0.016l-109.803,109.56c-2.332,2.327-4.052,5.193-5.01,8.345
            l-23.913,78.725c-2.12,6.98-0.273,14.559,4.821,19.78c3.816,3.911,9,6.034,14.317,6.034c1.779,0,3.575-0.238,5.338-0.727
            l80.725-22.361c3.322-0.92,6.35-2.683,8.79-5.119l109.573-109.367C491.279,351.032,491.279,312.968,467.884,289.572z
            M333.776,451.768l-40.612,11.25l11.885-39.129l74.089-73.925l28.29,28.29L333.776,451.768z M439.615,346.13l-3.875,3.867
            l-28.285-28.285l3.862-3.854c7.798-7.798,20.486-7.798,28.284,0C447.399,325.656,447.399,338.344,439.615,346.13z"
          />
          <path d="M332.459,120h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206c11.046,0,20-8.954,20-20S343.505,120,332.459,120z" />
        </svg>
        <span className="ml-1 text-gray-600 group-hover:text-primary" style={{ fontSize: '12px' }}>
          {t('Edytuj notatkę')}
        </span>
        <span className={`${content.length > 1 && showNote === false ? '' : 'hidden'} text-xs block p-1 px-2 bg-green-100 rounded mt-1 break-all min-w-60`}>
          {content}
        </span>
      </button>
      <div className={`${showNote ? '' : 'hidden'} flex flex-row relative w-full`}>
        <div className="flex flex-col flex-grow">
          <DivGrow className="text-sm grow-wrap">
            <textarea
              ref={refNote}
              className="block text-sm break-all bg-white border-gray-300 rounded min-w-60"
              defaultValue={content}
              onInput={() => onTextareaGrow()}
              // onBlur={onBlurSave}
              maxLength={254}
            />
          </DivGrow>
          <span className="text-xs text-gray-400">{t('maksymalnie 254 znaków')}</span>
        </div>
        <div className="absolute right-0 flex flex-row m-px -top-3"> 
          <button
            type="button"
            onClick={onClearNote}
            className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
            data-tooltip={t('wyczyść notatkę')}
          >
            <svg className="w-4 h-4 text-black bg-white rounded-full" viewBox="-1.5 -2.5 24 24" fill="currentColor">
              <path d='M12.728 12.728L8.485 8.485l-5.657 5.657 2.122 2.121a3 3 0 0 0 4.242 0l3.536-3.535zM11.284 17H14a1 1 0 0 1 0 2H3a1 1 0 0 1-.133-1.991l-1.453-1.453a2 2 0 0 1 0-2.828L12.728 1.414a2 2 0 0 1 2.828 0L19.8 5.657a2 2 0 0 1 0 2.828L11.284 17z'/>
            </svg>
          </button>
          <button
            type="button"
            onClick={onDiscardNote}
            className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
            data-tooltip={t('zamknij notatkę')}
          >
            <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
              <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
            </svg>
          </button>
          <button
            type="button"
            onClick={onSaveNote}
            className="p-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
            data-tooltip={t('zapisz notatkę')}
          >
            <svg className="w-4 h-4 bg-green-500 rounded-full" viewBox="-180 -220 750 750" fill="currentColor">
              <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Note;
