import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../../components/router/link';
import { GlobalDispatchContext } from '../../components/context/global';

import MobileBtn from '../../components/header/mobilebtn';
import Search from '../../components/header/search';
import SearchButton from '../../components/header/searchbutton';
import  '../../components/header/style.css';

import AlertMessage from '../../components/header/AlertMessage';
import TranslateGoogle from '../../components/translate/TranslateGoogle';
import TranslateInternal from '../../components/translate/TranslateInternal';
import WindowZoom from '../../components/WindowZoom';

import useWindowSize from '../../components/hook/useWindowSize';

import styled from 'styled-components';

const DivSearch = styled.div`
  transition: all 0.2s ease-in-out;
  visibility:hidden;
  opacity:0;
  transform: translateX(-50px);

  &.toggle {
    visibility:visible;
    opacity:1;
    transition: all 0.2s ease-in-out;
    transform: translateX(0);
  }

  @media (min-width: 1024px) {
    visibility:visible;
    opacity:1;
    transform: translateX(0);
  }
`;

export default function CatalogHeader() {
  // const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  const { t } = useTranslation('home');

  const [pathName, setPathName] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [clientWidth] = useWindowSize();


  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  const searchShow = /\/article|\/catalog|\/cart/.test(pathName);
  const searchMode = /\/article|\/catalog|/.test(pathName) ? 0 : 1;
  const transMode = document.querySelector('meta[name="portal-translate"]').content;

  return (
    <header className='header'>
      {/* <WindowZoom /> */}
      <nav className="bg-gray-100">
        <div className="px-4 mx-auto max-w-8xl sm:px-6">
          <div className="flex items-center justify-between h-8 gap-3 text-xs text-gray-800">
            <div>
              <AlertMessage catalog={true} />
            </div>
            {transMode == "2" && <TranslateGoogle />}
            {(transMode == "3" || transMode == "{portal_translate}") && <TranslateInternal />}
            <div className="flex-grow">&nbsp;</div>
          </div>
        </div>
      </nav>

      <nav className="relative bg-white">
        <span className="shadowround" />
        <div className="relative z-10 px-4 mx-auto bg-white max-w-8xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <MobileBtn isOpen={false} setIsOpen={(on) => globalDispatch.setMobile(on)} />

            <div className="flex items-center">
              <div className="flex-shrink-0 hidden p-4 md:flex">
                <Link to="/catalog">
                  <img className="h-8" src="/plugins/logo.png" data-cy="company-logo" alt="Humansoft logo" />
                </Link>
              </div>
            </div>

            {/* 
            <div className="justify-center flex-grow hidden lg:flex">
              <div className={`relative w-full px-2 text-base text-gray-600 transition-colors duration-150 bg-accents-1 flex items-center`}>
                <Search mode={searchMode} catalog={true} />
              </div>
            </div> 
            */}

            {searchShow && (
              <div className="flex justify-center flex-grow "> 
                <div className="flex items-center lg:hidden">
                  <SearchButton handleUpdate={(isShow) => setToggleSearch(isShow) } />
                </div>
                <div className='relative flex items-center w-full px-2 text-base text-gray-600 transition-colors duration-150 bg-accents-1'>
                  <DivSearch className={`${toggleSearch ? 'toggle' : ''} absolute bg-white z-10 left-0 w-56 lg:w-auto`}>
                    <Search mode={(clientWidth >= 1200 && searchMode == 0) ? 0 : 1} catalog={true} />
                  </DivSearch>
                </div>
              </div>
            )}

            <div className={`flex justify-center flex-grow ${searchShow ? 'hidden' : ''}`} />

            <div className="flex flex-row-reverse w-96">
              <Link to="/login">
                <span className="px-3 py-2 font-bold text-white transition duration-150 ease-in-out rounded bg-primary hover:bg-gray-700">
                  {t('Zaloguj się do B2B')}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
