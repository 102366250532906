import { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";
import api from '../service/api.ts';
import { parseJSON, truncateString, uid } from '../../components/util';
import { useCart } from '../../components/context/cart.ts';

import getUserData from '../UserData.ts';

import { GlobalDispatchContext } from '../../components/context/global';
import { useTranslation } from "react-i18next";
import IconSpinner from "../icon/IconSpinner.js";

const getContractors = async () => {
  const resp = await api.getTraderContractor();
  return resp?.data;
};

const postContractor = async (symbol) => {
  const resp = await api.postTraderContractor(symbol);
  return resp?.data;
};

const UserHan = ({ id = 0 }) => {
  const { t } = useTranslation("shop");
  const [, cartHook] = useCart();
  // const [, cartAction] = useCartAction();
  const context = useContext(GlobalDispatchContext);

  const [userList, setUserList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [chooseUser, setChooseUser] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const refList = useRef();
  const refSearch = useRef();
  let isMounted = false;

  const getContractorsMemory = useCallback(() => {
    isMounted && setLoading(true);
    getContractors().then(data => {
      if (data?.status != 0) return;

      const list = parseJSON(data?.dane);
      if (!list) return;

      isMounted && setChooseUser(data?.info || "");

      isMounted && setUserList(list.sort((a, b) => 
        a.skrot_ktr.trim().localeCompare(b.skrot_ktr.trim(), 'pl') 
      ));
    }).finally(() => isMounted && setLoading(false));
  }, [id]);

  useEffect(() => {
    isMounted = true;
    getContractorsMemory();
    return () => {
      isMounted = false;
    }
  }, [getContractorsMemory]);

  const onContractorSelect = (symbol_ktr) => {
    setShowList(false);
    setLoading(true);
    
    postContractor(symbol_ktr).then(data => {
      if (data?.status != 0) {
        setLoading(false);
        return;
      }

      setChooseUser(symbol_ktr);
      getUserData(context);
      cartHook.getWholeCart().then(() => {
        console.log("UserHan cart");
        // window.location.reload();
      }).finally(() => setLoading(false));
    });
  };

  const getChooseUser = () => {
    if (userList.length <= 0) return '';

    const user = userList.find(user => user.symbol_ktr == chooseUser);
    if (!user) return '';

    return `(${user.skrot_ktr}) `;
  };

  const onBlurList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refList.current && !refList.current.contains(document.activeElement)) {
        setShowList(false);
      }
    }, 100);
  };

  const onShowList = (e) => {
    e.preventDefault();
    setShowList(!showList);

    if (!showList) {
      setTimeout(() => {
        refSearch.current && refSearch.current.focus();
      }, 10);
    }
  };

  const onSearchFilter = (e) => {
    setSearch(""+e.target.value);
    return true;
  };

  const onClearFilter = () => {
    setSearch("");
    refSearch.current.value="";
  };

  const filterList = (user)  => {
    if (search == "") return true;

    const valSearch = search.toLowerCase();

    return (
      (user?.nazwa_ktr && user.nazwa_ktr.toLowerCase().includes(valSearch)) ||
      user.skrot_ktr.toLowerCase().includes(valSearch) ||
      user.miejsc.toLowerCase().includes(valSearch) ||
      user.ulica.toLowerCase().includes(valSearch) ||
      user.nip.toLowerCase().includes(valSearch)
    );
  };

  return (
    <div>
      <div className="flex-end">
        <div className="inline-block text-left xs:relative">
          <div>
            <button
              onClick={onShowList}
              onBlur={onBlurList}
              type="button"
              className={`relative inline-flex gap-1 text-primary`}
              title={getChooseUser(chooseUser)}
            >
              {loading && (
                <span className="absolute block w-full h-full bg-gray-100 bg-opacity-60">
                  <IconSpinner className="absolute w-16 h-4 -translate-x-1/2 left-1/2" />
                </span>
              )}
              {/* <span className={`${loading ? 'rainbow-font' : ''}`}> */}
              <span>
                {t('Kontrahent')}
                <span translate="no" className="hidden sm:inline-block">
                  &nbsp;{getChooseUser(chooseUser)}
                </span>
              </span>
              <svg className="w-4 h-4 mt-px" viewBox="0 0 24 24" fill="none">
                <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div
            tabIndex={-1}
            ref={refList}
            onBlur={onBlurList}
            className={`origin-top-left absolute left-0 z-20 m-width-20 transition-opacity mt-1 ring-1 ring-black ring-opacity-5 right-0 rounded-md bg-white shadow-lg outline-none ${showList ? 'opacity-100 visible' : 'invisible opacity-0'}`}
            style={{minWidth: "280px"}}
          >
            <div className="relative">
              <div className="p-2 border-b border-dashed">
                <div className="relative">
                  <input
                    ref={refSearch}
                    className="w-full pr-10 text-sm border-gray-300 rounded"
                    type="text"
                    placeholder={t('filtruj kontrahenta')}
                    onKeyUp={onSearchFilter}
                    defaultValue=""
                  />
                  <button className={`absolute ${search.length == 0 ? '' : 'hidden'} top-3 right-3 z-10`}>
                    <svg className="w-4 h-4 text-gray-500" viewBox="0 0 368.167 368.167" style={{ enableBackground: "new 0 0 368.167 368.167" }} fill="currentColor">
                      <path d="M248.084,96.684h12c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-12c-4.4,0-8,3.6-8,8C240.084,93.084,243.684,96.684,248.084,96.684z"/>
                      <path d="M366.484,25.484c-2.8-5.6-8.4-8.8-14.4-8.8h-336c-6,0-11.6,3.6-14.4,8.8c-2.8,5.6-2,12,1.6,16.8l141.2,177.6v115.6
                        c0,6,3.2,11.2,8.4,14c2.4,1.2,4.8,2,7.6,2c3.2,0,6.4-0.8,9.2-2.8l44.4-30.8c6.4-4.8,10-12,10-19.6v-78.8l140.8-177.2
                        C368.484,37.484,369.284,31.084,366.484,25.484z M209.684,211.884c-0.8,1.2-1.6,2.8-1.6,4.8v81.2c0,2.8-1.2,5.2-3.2,6.8
                        l-44.4,30.8v-118.8c0-2.8-1.2-5.2-3.2-6.4l-90.4-113.6h145.2c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-156c-0.4,0-1.2,0-1.6,0l-38.4-48
                        h336L209.684,211.884z"/>
                    </svg>
                  </button>
                  <button 
                    className={`absolute ${search.length > 0 ? '' : 'hidden'} z-10 outline-none`}
                    style={{top:"7px", right:"7px"}}
                    onMouseDown={onClearFilter}
                  >
                    <svg className="w-6 h-6 text-gray-500" viewBox="-220 -250 800 800" fill="currentColor">
                      <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
                    </svg>
                  </button>
                </div>
              </div>
              <ul
                role="listbox"
                aria-labelledby="contractor-label"
                aria-activedescendant="contractor-0"
                className="py-1 overflow-auto text-sm focus:outline-none sm:text-sm"
                style={{ maxHeight: "349px" }}
              >
                {userList.filter(val => filterList(val)).length == 0 && (
                  <div className="relative px-2 py-2 text-center text-yellow-700 transition bg-yellow-100 rounded" role="alert">
                    <span className="block text-sm sm:inline">{t('Brak kontrahentów')}</span>
                  </div>
                )}
                {userList.length > 0 && userList
                  .filter(val => filterList(val))
                  .sort((a, b) => (a.symbol_ktr == chooseUser && b.symbol_ktr != chooseUser) ? -1 : 1)
                  .map((val) =>
                    <li 
                      key={`contractor_choose_${val.symbol_ktr}`} 
                      id={`contractor-${val.symbol_ktr}`} 
                      role="option" 
                      aria-selected="false" 
                      className={`group relative py-2 pl-3 pr-6 cursor-default select-none border-t first:border-t-0 hover:text-white hover:bg-primary ${val.symbol_ktr == chooseUser ? 'bg-primary text-white' : 'text-gray-700'}`}
                    >
                      <button
                        type="button"
                        onMouseDown={(e) => { onContractorSelect(val.symbol_ktr) }}
                        className={`w-full outline-none`}
                      >
                        <div
                          className="flex flex-col items-start"
                          translate="no"
                          title={val?.nazwa_ktr || ""}
                        >
                          {/* <div className="block -mt-px overflow-hidden text-sm font-semibold truncate whitespace-nowrap overflow-ellipsis" style={{ maxWidth: "220px" }}> */}
                          <div className="block -mt-px text-sm font-semibold truncate whitespace-nowrap">
                            {truncateString(val.skrot_ktr, 28)}
                          </div>
                          <div className={`block text-xs text-left group-hover:text-gray-100 ${val.symbol_ktr == chooseUser ? 'text-gray-100' : 'text-gray-600'}`}>
                            {val.nip.length > 0 && (
                              <>
                                {val.nip}<br />
                              </>
                            )}{val.miejsc}, {val.ulica}
                          </div>
                        </div>
                      </button>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHan;
