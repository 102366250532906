import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const treeStash = {
  handler: null,
  checked: [],
  selected: [],
  setSelected: null,
  clicked: '',
  setClicked: '',
};

function onTree(node) {
  const index = treeStash.selected.findIndex(el => el.trim() === node.trim());

  if (index >= 0)
    treeStash.selected.splice(index);
  else
    treeStash.selected.push(node);

  treeStash.setSelected(treeStash.selected);
}

function onShowTree(nodeKey) {
  // let regexp = new RegExp(`^${nodeKey}(A-Z0-9a-z)*`);
  // console.log(`^${nodeKey}(A-Z0-9a-z)*`, regexp.test(treeStash.clicked))
  // return regexp.test(treeStash.clicked) || treeStash.selected.find(e => e === nodeKey);
  const foundNode = treeStash.selected && treeStash.selected.find(e => e.trim() === nodeKey.trim());
  return foundNode !== undefined;
}

function genSelectedTree(node) {
  return node.split('-').map((_, i, arr) => {
    return arr[i] = arr[i-1] ? arr[i-1].concat('-' + arr[i]) : arr[i];
  });
}

function TreeNode({ nodes, parent, level, catalog=false }) {
  const [_, setRefresh] = useState(-1);
  const navigate = useNavigate();

  if (typeof nodes === 'undefined' || nodes.length == 0)
    return '';


  const itemList = [];
  const banList = [];
  let count = 0;

  for (let el of nodes) {
    count++;

    if (banList.length > 0 && banList.some(ban => el.node_key.startsWith(ban.node_key))) {
      continue;
    }

    if (el.node_level < level) {
      banList.push(el);
      continue;
    }

    if (el.node_level != level || (el.parent != parent && !(parent == "" && level > 0)))
      continue;

    itemList.push([
      <li
        key={el.node_key}
        data-level={level}
        className={`${treeStash.clicked.trim() == el.node_key.trim() ? 'active' : ''}`}
        data-node={el.node_key}
      >
        {/* <input type="checkbox" className="text-primary" onClick={(e) => onCheckedTree(el.node_key, e.target.checked)} /> */}
        <button
          type="button"
          onClick={() => {
            if (!el.child) return;
            onTree(el.node_key);
            setRefresh(Math.random());
          }}
          className={`${onShowTree(el.node_key) ? 'show' : ''} focus:outline-none hover:text-primary transition duration-500 ease-in-out transform hover:scale-150`}
        >
          <svg
            className={`${!el.child ? 'text-gray-100 hidden' : 'inline-block'} h-4 w-4 `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 5l7 7-7 7" />
          </svg>
          <svg
            className={`${!el.child ? 'inline-block' : 'hidden'} h-4 w-4 `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M 6 12 L 18 12" />
          </svg>
        </button>
        <a
          href={`${!catalog ? '/article' : '/catalog'}#${el.node_key}`}
          data-href={`${!catalog ? '/article' : '/catalog'}#${el.node_key}`}
          data-node={el.node_key}
          onClick={(e) => {
            e.preventDefault();
            // console.log("HANDLER", el.node_key)
            treeStash.setClicked(el.node_key);
            treeStash.setSelected(genSelectedTree(el.node_key));
            treeStash.handler([el.node_key]);
            navigate(e.currentTarget.dataset.href);
            // document.location.hash = e.currentTarget.dataset.node;
            // document.location.replace(e.currentTarget.dataset.href);
          }}
          title={el.text}
        >
          {el.text.replace(/(.{50})..+/, "$1…")}
        </a>
        <TreeNode nodes={[...nodes.slice(count)]} parent={el.node_key} level={(level + 1)} catalog={catalog} />
      </li>
    ]);
  };

  if (itemList.length == 0)
    return '';

  return (
    <ul>
      {itemList}
    </ul>
  );
}

export default function Tree2({ nodes, onHandler, selected=[''], catalog = false }) {
  if (nodes.length == 0) {
    return '';
  }

  nodes.map(el => {
    el["child"] = nodes.find(el2 => el2.parent == el.node_key) !== undefined
    return el;
  });

  useEffect(() => {
    // if(treeStash.selected[0] != selected[0])
    const selectNode = selected[0];

    treeStash.setClicked(selectNode);
    treeStash.setSelected(genSelectedTree(selectNode));
    // console.log('TREE2 init', selectNode, treeStash.selected);
  }, [selected]);

  treeStash.handler = onHandler;
  ({ 0: treeStash.selected, 1: treeStash.setSelected } = useState([]));
  ({ 0: treeStash.clicked, 1: treeStash.setClicked } = useState(''));

  return (
    <>
      <div className="w-full nav-tree">
        <TreeNode nodes={nodes} parent="" level={0} catalog={catalog} />
        <TreeNode nodes={nodes} parent="" level={1} catalog={catalog} />
        <TreeNode nodes={nodes} parent="" level={2} catalog={catalog} />
      </div>
    </>
  )
}
