import { useState, useEffect, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import storage from '../../components/util/storage';
import { GlobalDispatchContext, GlobalStateContext } from '../../components/context/global';
import { SpinnerFixed } from '../../components/spinner';

import Pager from '../../components/pager/pager';
import PageNum from '../../components/pager/pagenum';
import PageInfo from '../../components/pager/pageinfo';

import RibbonButton from '../../components/side/ribbonbutton';
import FaviButton from '../../components/side/favibutton';
import FilterButton from '../../components/side/filterbutton';
import FilterList from '../../components/side/filterlist';
import FilterActive from '../../components/side/filteractive';
// import FilterClear from '../../components/side/filterclear';
// import MultiSelect from '../../components/multiselect/multiselect';
import Switch from '../../components/switch';
import Alert from '../../components/alert';

import ArticleSwitch from './switch';
import ArticleGrid from './grid';
import ArticleTile from './tile';
import BreadCrumbs from './breadcrumbs';

import LazyElement from '../../components/hook/lazyelement';

import api from '../../components/service/api.ts';
import { parseJSON, getSearchArticleSQL, toBoolean } from '../../components/util';

import usePrevious from '../../components/hook/previous';
import useMenuTree from '../../components/hook/menu/menutree';
import useCartAction from '../../components/hook/cartaction';
import useSessionState from '../../components/hook/sessionstate.ts';

import useDidMountEffect from '../../components/hook/useDidMountEffect';

import MenuSide from '../../components/side/menuside';
import ArticlePdf from './ArticlePdf';

import './style.css';

let timeoutArticle = null;

const getCategoryHash = () => {
  const { hash } = document.location;
  if (hash.length > 1) {
    const category = hash.slice(1).split('|')[0].toString();
    return [decodeURI(category)];
  }

  return [''];
};

const getPageHash = () => {
  const { hash } = document.location;
  if (hash.length > 1) {
    const hashSplit = hash.slice(1).split('|');
    const page = (hashSplit.length > 1) ? parseInt(hashSplit[1], 10) : 0;

    // console.log("PAGE hash", page);
    return +page;
  }

  return 0;
};

const getSortFromSession = () => {
  const sessionSort = storage.get('local-sort-mode', '');
  if (!sessionSort || sessionSort.length == 0) return '';

  const sortObj = JSON.parse(sessionSort);
  return `${sortObj.symbol} ${sortObj.sort}`;
};

const parsePageMax = (val) => {
  switch (+val) {
    case 12: return 12;
    case 20: return 20;
    case 50: return 50;
    default: return 12;
  }
};

const Article = () => {
  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);

  if (typeof globalState.user?.modules !== 'object' || globalState.user?.modules[0] != 1)
    return '';

  const { t } = useTranslation('shop');
  const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0);

  const [loading, setLoading] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [category, setCategory] = useState(getCategoryHash());
  const [newCategory, setNewCategory] = useState(getCategoryHash());
  const [page, setPage] = useState(getPageHash());
  const [search, setSearch] = useState(getSearchArticleSQL(globalState.search));
  const [searchType, setSearchType] = useState(globalState.searchType);
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [sort, setSort] = useState(getSortFromSession());
  const [sortLaunch, setSortLaunch] = useState(getSortFromSession());
  const [maxItems, setMaxItems] = useState(0);
  const [pageMax, setPageMax] = useState(parsePageMax(storage.get('local-page-max', 12)));
  const [available, setAvailable] = useState(toBoolean(storage.get('local-stock-available', 0)));
  const [infinityScroll, setInfinityScroll] = useState(false);
  const [quantityList, setQuantityList] = useState({});
  const [priceList, setPriceList] = useState({});
  const [imageList, setImageList] = useState({});
  const [ribbonList, setRibbonList] = useState({});
  const [displayMode, setDisplayMode] = useSessionState('displayMode', 0);
  const [pathTree, setPathTree] = useState({ [t('Zakupy')]: '/article' });
  const [firstRender, setFirstRender] = useState(true);
  // const [alertConnect, setAlertConnect] = useState(false);
  const [faviState, setFaviState] = useState(false);
  const [stateCart] = useCartAction();

  const prevPage = usePrevious(page);
  const prevCategory = usePrevious(category);

  const [fetchState, setFetchState] = useState({ cancel: () => {} });

  const getArticles = async ({ category = [''], pageMax, page = 0, search = "", searchType = 0, filter = {}, sort = '', available = false }) => {
    window && window.scrollTo({ top: 0, behavior: 'smooth' });

    const dataSort = sort.split(' ');
    storage.set('local-sort-mode', dataSort.length > 1 ? JSON.stringify({ symbol: dataSort[0], sort: dataSort[1] }) : "");

    const dataCategory = (category.length > 0 || category[0] != '') ? category : [''];
    const dataLang = storage.get('global-lang', 'pl');
    const dataFilter = filter != null && Object.keys(filter).length <= 0 ? null : filter;

    await fetchState.cancel();
    // const promise = api.getArticlesFetch({
    const promise = api.getArticlesFetch({
      nodekeys: dataCategory,
      offset: page * pageMax,
      perpage: pageMax,
      orderby: sort,
      filtr: search,
      filtr_cechy: dataFilter,
      available,
      lang: dataLang,
    });

    setFetchState(promise);
    setLoading(true);
    const response = await promise;
    setLoading(false);
    return response?.data;
  };

  const handleCategory = (cat) => {
    // console.log("handleCategory", cat);

    const categoryFirst = decodeURIComponent(cat[0]);
    // console.log("%cHANDLE CAT", "color:red")

    menuTreePath([categoryFirst]).then(menuTree => {
      if (menuTree.length == 0) {
        return;
      }

      const tree = Object.fromEntries(
        Object.entries(menuTree)
          .map(([key, val]) => [key, '/article' + val])
      );

      // console.log('%cpathTree', 'color:red', categoryFirst, menuTree, tree);
      setPathTree({ [t('Zakupy')]: '/article', ...tree });
    });

    setCategory([categoryFirst]);
  };

  const [MenuTreeRender, menuTreePath] = useMenuTree({ handleCategory, newNode: newCategory });

  const getCategoryFromHash = (path) => {
    if (path.split('#').length < 2 || path.split('#')[1] === '') {
      return false;
    }

    const hashSplit = path.split("#")[1].split("|")[0];
    return decodeURI(hashSplit);
  };

  const handleBreadCrumbs = (path) => {
    let categoryFromHash = getCategoryFromHash(path);
    if (categoryFromHash == false) 
      categoryFromHash = '';

    setNewCategory([categoryFromHash]);
    return true;
  };

  const handleSwitchAvailable = (value) => {
    storage.set('local-stock-available', value);
    setPage(0);
    setAvailable(value);
  };

  const handlePageMax = (num) => {
    storage.set('local-page-max', num);
    setPage(0);
    setPageMax(num);
  };

  const loadInfinityArticle = (isVisible) => {
    console.log('loadInfinityArticle: load', articleList.length, isVisible, page, infinityScroll);

    if (isVisible === true && articleList.length > 0 && infinityScroll && ((page + 1) * pageMax) < maxItems) {
      console.log('loadInfinityArticle: page', page);
      setPage(page + 1);
    }
  };

  const switchInfinityScroll = (isOn) => {
    console.log('InfinityScroll: switch', isOn);
    setInfinityScroll(isOn);
  };

  const filterClear = () => {
    console.log("FilterClear")
    setSearch('');
    setFilter({});
    setFaviState(false);
    setPage(0);
    setRibbonList({});
  };

  const changeFaviState = (isOn) => {
    // setSearch('');
    // setFilter({});
    // setPage(0);
    // setRibbonList({});
    // TODO: get first category
    setFaviState(isOn);
    // setNewCategory(['']);
  };

  function arrEvery(a1,a2) {
    return a1.every((v,i)=> v === a2[i]);
  }

  useEffect(async () => {
    if (firstRender || prevCategory == undefined) return; // arrEvery(category, prevCategory)
    console.log("FIRE category", category, prevCategory, newCategory);

    if (page != 0) setPage(0);
    setFilter({});

    globalDispatch.setFilter({});
    globalDispatch.setCategory(category[0]);

    if (!(globalState.searchType == 0 && category[0] == '')) {
      globalDispatch.setSearch('');
    }
  }, [category]);

  useEffect(() => {
    if (firstRender) return;
    console.log("FIRE infinityscroll");

    setPage(0);

    if (infinityScroll) {
      loadInfinityArticle(true);
    }
  }, [infinityScroll]);

  useEffect(() => {
    if (firstRender) return;
    console.log("FIRE search");

    if (search.length > 0 && globalState.search.length == 0 ) {
      setSearch('');
      return;
    }

    setPage(0);
    setSearch(getSearchArticleSQL(globalState.search));
    globalState.searchType == 0 && setNewCategory(['']);
  }, [globalState.search]);

  useEffect(() => {
    if (firstRender || globalState.search.length == 0) return;
    console.log("FIRE searchType");

    setPage(0);
    setSearch(getSearchArticleSQL(globalState.search));
    globalState.searchType == 0 && setNewCategory(['']);
  }, [globalState.searchType]);

  useEffect(() => {
    if (firstRender) return;
    console.log('FIRE filter', globalState.filter);
    // const filterMap = Object
    //   .entries(globalState.filter)
    //   .filter((val) => val[1] !== undefined)
    //   .reduce((prev, curr) => (
    //     { ...prev, [curr[0]]: curr[1].value }
    //   ), {});

    const filterMap = globalState.filter;

    setPage(0);
    setFilter(filterMap);
  }, [globalState.filter]);

  useEffect(() => {
    if (firstRender) return;
    console.log('FIRE sortLaunch');

    setPage(0);
    setSort(sortLaunch);
  }, [sortLaunch]);

  const debounceArticle = () => {
    // if (category.length === 0 || category[0] === '') return false;
    console.log('FIRE debounce', "prevPage " + prevPage, "page " + page, "cat " + category, globalState.category, forceUpdate);

    // // reset search after change category
    // if (globalState.search != '' && globalState.category != category[0]) {
    //   console.log("%cGLOBAL SEARCH EMPTY", "color:red");
    //   globalDispatch.setSearch('');
    // }

    getArticles({ category, pageMax, page, search, searchType, filter, sort, available })
      .then(r => {
        if (r?.status != 0) return false;
        // TODO: temporary filter articles
        setMaxItems(parseInt(r?.count));

        let artData = parseJSON(r?.dane);
        if (artData === '' || artData === false) return [];

        return artData;
      })
      .then((artData) => {
        if (infinityScroll)
          setArticleList(artData == false || artData.length === 0 ? [...articleList] : [...articleList, ...artData]);
        else
          setArticleList(artData == false || artData.length === 0 ? [] : [...artData]);
      })
      .finally(_ => {
        setFirstRender(false);
      })
      .catch(err => {
        console.log('ART err', err);
      });
  };

  useEffect(() => {
    if (firstRender) return;
    console.log('FIRE page', page, "pageMax", pageMax);

    const hash = document.location.hash;
    if (hash.length <= 1) return;

    const hashSplit = hash.slice(1).split('|');
    document.location.hash = `#${hashSplit[0]}|${page}`;
  }, [page]);

  // useEffect(() => {
  //   if (firstRender) return;

  //   const hash = document.location.hash.split("|");
  //   if (hash.length < 2) hash.push(0);

  //   const hashPage = parseInt(hash[1], 10);

  //   if (hashPage < 0) {
  //     document.location.hash = hash[0] + "|0";
  //     return;
  //   }

  //   if (hashPage > parseInt(Math.ceil(maxItems / pageMax))) {
  //     document.location.hash = hash[0] + "|" + pageMax;
  //     return;
  //   }

  //   setPage(parseInt(hash[1], 10));
  // }, [document.location.hash]);

  useDidMountEffect(() => {
    console.log('FIRE main', category, prevPage, page, search, filter, sort, pageMax, available, forceUpdate, firstRender);

    // TODO: fix multiple fire article list
    if (timeoutArticle != null) clearTimeout(timeoutArticle);
    timeoutArticle = setTimeout(debounceArticle, 150);
    // timeoutArticle = setTimeout(debounceArticleFetch, 100);

    return () => {
      // console.log("unmount index");
      // setArticleList(undefined)
      // setQuantityList({})
      // setPriceList({})

      Object.values(imageList).forEach(v => URL.revokeObjectURL(v));
      setImageList({});
    };
  }, [category, page, search, filter, sort, pageMax, available, forceUpdate, globalState.lang]);
  // }, []);

  return (
    <>
      <MenuSide showSearch>
        <div>
          {MenuTreeRender}
        </div>
        {!!globalState.user?.favorite_display && (category.length > 0 && category[0] !== '') && (
          <div>
            <FaviButton defaultValue={faviState} handleChange={(isOn) => changeFaviState(isOn)} />
          </div>
        )}
        {!!globalState.user?.ribbon_display && (category.length > 0 && category[0] !== '') && (
          <div>
            <RibbonButton clear={Object.keys(ribbonList).length} handleUpdate={(list) => setRibbonList(list)} />
          </div>
        )}
        {(!!globalState.user?.filter_show == false) && (
          <FilterButton nodeKey={category} handleData={(data) => setFilterData(data)} />
        )}
      </MenuSide>
      <main className="w-full px-4 py-4 m-2">
        <div className="inline-flex flex-wrap w-full">
          <div className="flex items-center content-center flex-grow pb-4 flex-start">
            <BreadCrumbs path={pathTree} handleClick={handleBreadCrumbs} />
          </div>
          {!!globalState.user?.is_han && !!globalState.user?.article_pdf && (
            <div className="flex items-center content-center pb-4 pl-6">
              <ArticlePdf category={category} search={search} sort={sort} filter={filter} available={available} />
            </div>
          )}
          <div className="flex items-center content-center pb-4 pl-6">
            <Switch label={t('dostępne')} handleSwitch={handleSwitchAvailable} defaultValue={available} />
          </div>
          {/*
          <div className="items-center content-center hidden pb-4 pl-6 md:flex">
            <Switch label="nieskończone" label2="przewijanie" color="bg-yellow-500" handleSwitch={switchInfinityScroll} />
          </div>
          */}
          <div className="flex items-center content-center pb-4 ml-auto">
            <ArticleSwitch handleDisplayMode={setDisplayMode} initView={displayMode} />
          </div>
        </div>

        {globalState.user?.filter_show == true && (
          <FilterList nodeKey={category} handleData={(data) => setFilterData(data)} />
        )}
        <FilterActive filterData={filterData} />

        <div className="flex flex-col">
          <div className="relative inline-block h-full min-w-full min-h-full mb-2 align-middle">
            {/* START CONTENT */}
            {/* <Spinner area="product-list" /> */}
            {loading && <SpinnerFixed />}

            {/*
            {fetchState.status !== "fetched" && (
              <Alert content="LOADING" color="green" />
            )}
            */}

            {/* {(alertConnect) && <Alert content="Błąd połączenia: cena i zapas w podstawowej wartości." color="yellow" />} */}
            {(firstRender === false && articleList && articleList.length === 0 && search.length > 0) && <Alert content="Brak artykułów dla danego filtru wyszukiwania" color="yellow" />}

            {firstRender === false && displayMode === 0 && (
              <ArticleGrid
                loading={loading}
                data={articleList || []}
                handleSort={(key, direction) => setSortLaunch(`${key} ${direction}`.trim())}
                handleUpdate={() => 1!=1 && setForceUpdate(1)}
                menuTreePath={menuTreePath}
              />
            )}

            {(firstRender === false && displayMode === 1) && (
              <ArticleTile
                loading={loading}
                data={articleList || []}
                handleSort={(key, direction) => setSortLaunch(`${key} ${direction}`.trim())}
                handleUpdate={() => 1!=1 && setForceUpdate(1)}
                menuTreePath={menuTreePath}
              />
            )}

            <LazyElement handlerLoad={loadInfinityArticle} />

            {infinityScroll !== true && maxItems > 0 && (
              <div className="sticky bottom-0 flex flex-wrap items-center justify-between px-4 pb-4 bg-white border-gray-200 flex-end md:border-t sm:px-6">
                <div className="px-2 pt-4 mx-auto md:mx-0">
                  <PageInfo page={+page} maxItems={+maxItems} pageMax={+pageMax} handlePage={setPage} />
                </div>
                <div className="flex justify-center flex-grow px-2 pt-4 mx-auto md:mx-0 md:justify-end">
                  <Pager page={+page} pageMax={+pageMax} maxItems={+maxItems} handlePage={setPage} />
                </div>
                <div className="justify-center flex-shrink-0 px-2 pt-4 mx-auto">
                  <PageNum pageMax={+pageMax} handleChange={handlePageMax} />
                </div>
              </div>
            )}
            {/* END CONTENT */}
          </div>
        </div>
      </main>
    </>
  );
};

export default Article;
