import { useState, useEffect, useRef, useContext } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { useParams } from "react-router-dom"
import parser from 'bbcode-to-react';
import { useTranslation } from 'react-i18next';

// import { GlobalDispatchContext } from '../../components/context/global';
import { GlobalStateContext } from '../components/context/global';

import GalleryDialog from '../components/modal/gallery';
import api from '../components/service/api';
import { parseJSON } from '../components/util/index';
import { SpinnerFixed } from '../components/spinner';
import useCartAction from '../components/hook/cartaction';
import ReactHtmlParser from 'react-html-parser';
import LazyImage from '../components/hook/lazyimage';

import SSelect from '../components/grid/sselect';

import { numberFormatCurrency, roundTo } from '../components/util/index';
import BreadCrumbs from '../pages/article/breadcrumbs';

import useMenuTree from '../components/hook/menu/menutree';

import DocumentList from '../components/list/DocumentList';
import FeatureList from '../components/list/FeatureList';
import useFileCache from '../components/hook/useFileCache';
import Alert from '../components/alert';

import MenuSide from '../components/side/menuside';

import ArticleGrid from '../pages/article/grid';

const getArticle = async (symbol) => {
  const resp = await api.getCatalogArticleBySymbol(symbol);
  return resp?.data;
};

const getArticleCrossSell = async (symbol) => {
  const resp = await api.getCatalogArticleCrossSelling(symbol);
  return resp?.data;
};

const getArticleVariant = async (symbol) => {
  const resp = await api.getCatalogArticleVariantsBySymbol(symbol);
  return resp?.data;
};

const getArticleStickerCount = async (symbol) => {
  const response = await api.getArticleFilesCount(symbol, 'E');
  return response?.data;
};

const ArticleTabs = ({ item, files = [] }) => {
  const { t } = useTranslation("shop");

  const [articleCrossList, setArticleCrossList] = useState([]);
  const [articleCrossMultiList, setArticleCrossMultiList] = useState([]);
  const [articleVariantList, setArticleVariantList] = useState([]);
  const [articleDocumentList, setArticleDocumentList] = useState([]);
  const [articleFeatureList, setArticleFeatureList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tabNum, setTabNum] = useState(1);
  const [tabList, setTabList] = useState([
    { title: t('Opis') },
    { title: t('Cechy'), state: 0},
    { title: t('Do\u00A0pobrania'), state: 1 },
    { title: t('Warianty'), state: 2 },
    { title: t('Powiązane'), state: 3 },
    { title: t('Powiązane wielo'), state: 4 },
  ]);

  const stateBag = [
    articleFeatureList,
    articleDocumentList,
    articleVariantList,
    articleCrossList,
    articleCrossMultiList,
  ];

  const tabShow = (e, tab) => {
    e && e.preventDefault();
    setTabNum(tab);
  };
  // return () => {

  // };
  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      getArticleVariant(item.symbol_art), 
      getArticleCrossSell(item.symbol_art)])
      .then(results => {
        const list = [[], [], []];

        if (results[0].value?.status == 0) {
          list[0] = parseJSON(results[0].dane);
        }

        if (results[1].value?.status == 0) {
          const crossList = parseJSON(results[1].value?.dane);
          list[1] = crossList.filter(el => el.typ !== "W");
          list[2] = crossList.filter(el => el.typ === "W");
        }

        Array.isArray(list[0]) && setArticleVariantList(list[0]);
        Array.isArray(list[1]) && setArticleCrossList(list[1]);
        Array.isArray(list[2]) && setArticleCrossMultiList(list[2]);

        if (list[0]?.length > 0 && list[0][0].tytul != undefined)
          tabList[3].title = list[0][0].tytul;

        if (list[1]?.length > 0 && list[1][0].tytul != undefined)
          tabList[4].title = list[1][0].tytul;

        if (list[2]?.length > 0 && list[2][0].tytul != undefined)
          tabList[5].title = list[2][0].tytul;

        setTabList([...tabList]);
      // list.length = 0;
      }).finally(() => {
        setLoading(false);
      });

    return () => {
      item = null;
    };
  }, []);

  const hashCode = s => (""+s).split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

  return (
    <div className="w-full mt-2 tabs">
      <ul className="flex flex-col justify-center font-medium border-b border-gray-100 tab_header md:flex-row">
        {tabList.map((tab, index) => (
          <li key={`article_show_tab_${hashCode(tab.title) + index + 1}`} className={`${tab?.state == undefined || stateBag[tab.state]?.length > 0 ? 'block' : 'hidden'}`}>
            <a
              onClick={(e) => tabShow(e, index + 1)}
              className={`${tabNum == index + 1 ? 'border-primary text-primary' : ''} block px-8 py-2 border-b-2 border-transparent hover:border-b-2 hover:border-primary hover:text-primary text-center`}
              href={void(0)}
            >
              {tab.title}
            </a>
          </li>
        ))}
        {loading && (
          <li className='items-center'>
            <button className={`text-primary hover:cursor-pointer py-3 pb-2`}>
              <svg className='h-5 w-28' viewBox="0 0 20 20" preserveAspectRatio='xMinYMid'>
                <g transform="translate(10 10)">
                  <circle cx="0" cy="0" r="5" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
                <g transform="translate(30 10)">
                  <circle cx="0" cy="0" r="5" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
                  <g transform="translate(50 10)">
                  <circle cx="0" cy="0" r="5" fill="var(--primary)">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                  </circle>
                </g>
              </svg>
            </button>
          </li>
        )}
      </ul>
      <div className="px-0 py-5 tab_content md:p-5">
        <section className={`${tabNum == 1 ? 'block' : 'hidden'} transition duration-500 ease-in-out normalized`}>
          {ReactHtmlParser(item.opis_html)}
          {/* {ReactHtmlParser(item.opis_html || String(item.long_desc).replaceAll("\n", "<br/>").replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;"))} */}
        </section>
        <section className={`${tabNum == 2 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          <FeatureList list={item?.cechy} handleCount={(count) => count > 0 && setArticleFeatureList([count])} />
        </section>
        <section className={`${tabNum == 3 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          <DocumentList symbol={item.symbol_art} handleCount={(count) => count > 0 && setArticleDocumentList([count])} />
        </section>
        <section className={`${tabNum == 4 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleVariantList.length > 0 && (
            <ArticleGrid
              data={articleVariantList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              noDrag={true}
              catalog={true}
              noVariant={true}
              noHeader={false}
            />
          )}
        </section>
        <section className={`${tabNum == 5 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleCrossList.length > 0 && (
            <ArticleGrid
              data={articleCrossList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              noDrag={true}
              catalog={true}
              noVariant={true}
              noHeader={false}
            />
          )}
        </section>
        <section className={`${tabNum == 6 ? 'block' : 'hidden'} transition duration-500 ease-in-out`}>
          {articleCrossMultiList.length > 0 && (
            <ArticleGrid
              data={articleCrossMultiList}
              handleSort={(key, direction) => false}
              handleUpdate={() => false}
              noDrag={true}
              catalog={true}
              noVariant={true}
              noHeader={false}
            />
          )}
        </section>
      </div>
    </div>
  );
};

const ImageGallery = ({ item, imageCount = 0 }) => {
  const globalState = useContext(GlobalStateContext);
  const [state, cartAction] = useCartAction();
  const [imageCurr, setImageCurr] = useState(0);
  const [imageTemplate, setImageTemplate] = useState([]);
  const refImageGallery = useRef();
  const [loadArticleImage] = useFileCache();

  const changeFavorite = (isOff) => {
    cartAction.setFavorite(item, isOff);
  };

  useEffect(() => {
    // console.log("IMAGE TEMPLATE");

    setImageTemplate([
      <div className="relative" key={"image_main_"+ imageCurr}>
        {globalState.user?.favorite_display && (
          <FaviButton
            value={state.favorite[item.symbol_art]}
            onChange={changeFavorite}
            defaultClassName={`${'absolute top-1 right-1 z-10 p-1'}`}
          />
        )}
        <LazyImage
          className=""
          src=""
          symbol={item.symbol_art}
          width="440"
          height="440"
          alt=""
          random={Math.random()*100}
          handlerLoad={(symbol) => loadArticleImage(symbol, 440, imageCurr)}
        />
      </div>
    ]);
  }, [imageCurr]);

  const showImage = (index) => {
    setImageCurr(index);
  };

  const scrollImageGallery = (direction) => {
    const amount = 92;

    if (direction === "up")
      refImageGallery.current.scrollTop -= amount;

    if (direction === "down")
      refImageGallery.current.scrollTop += amount;
  };

  const showGallery = (e) => {
    e.preventDefault();
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const onChangeImage = (data) => {
      if (data.current >= 0 && data.current < imageCount) {
        setTimeout(() => {
          // closeModal();
          renderGallery(data.current);
        }, 0);
      }
    };

    const renderGallery = (current = 0) => {
      render(
        <GalleryDialog
          title="Informacja"
          open
          onClose={closeModal}
          onChange={onChangeImage}
          imageCurr={current}
          imageCount={imageCount}
        >
          <LazyImage
            refresh={Math.random()*100}
            className=""
            src=""
            symbol={item.symbol_art}
            width="1200"
            height="0"
            alt=""
            random={"gallery_full_" + (Math.random()*100)}
            handlerLoad={(symbol) => loadArticleImage(symbol, 1200, current)}
          />
        </GalleryDialog>,
        container,
      );
    };

    renderGallery(imageCurr);
  };

  return (
    <div className="flex">
      <div className="flex flex-col mr-4">
        {imageCount > 1 && (
          <>
            {imageCount > 4 && (
              <button
                onClick={() => scrollImageGallery("up")}
                className="flex justify-center w-full p-1 text-center rounded hover:border-primary hover:text-primary "
                type="button"
              >
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                  <path d="M19 16L12 9L5 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
            <div
              ref={refImageGallery}
              className="relative flex flex-col my-1 overflow-hidden"
              style={{ maxHeight: '368px' }}>
              {Array.from(Array(imageCount).keys()).map((img) => (
                <button
                  onClick={() => showImage(img)}
                  key={`article_show_image_${img}`}
                  type="button"
                  className={`border-2 m-1 focus:outline-none ${imageCurr == img ? 'border-primary' : ''}`}
                >
                  <picture>
                    <LazyImage
                      className=""
                      src=""
                      symbol={item.symbol_art}
                      width="80"
                      height="80"
                      alt=""
                      handlerLoad={(symbol) => loadArticleImage(symbol, 80, img)}
                    />
                  </picture>
                </button>
              ))}
            </div>
            {imageCount > 4 && (
              <button
                onClick={() => scrollImageGallery('down')}
                className="flex justify-center w-full p-1 text-center rounded hover:border-primary hover:text-primary"
                type="button"
              >
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                  <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
          </>
        )}
      </div>
      <picture className="relative transition duration-200 ease-in-out bg-white">
        <button
          type="button"
          onClick={showGallery}
          className="flex items-center h-full group"
        >
          {imageTemplate}
          {/* ICON search zoom */}
          <svg className='absolute z-10 text-gray-500 transition-all -translate-x-1/2 -translate-y-1/2 w-7 h-7 group-hover:text-primary group-hover:w-8 group-hover:h-8 -bottom-1 -right-1 ' viewBox="0 0 24 24" fill="none">
            <path d="M9.19995 11.7H14.2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7 14.2V9.19995" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" 
                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </picture>
    </div>
  );
};

const StickerGallery = ({ symbol, imageCount = 0 }) => {
  const [,,,,loadArticleSticker] = useFileCache();

  if (imageCount == 0) {
    return '';
  }

  return (
    <div className="flex">
      <div className="flex flex-row space-x-4">
        {Array.from(Array(imageCount).keys()).map((img, index) => (
          <picture key={`sticker_${index}`}>
            <LazyImage
              className=""
              src=""
              symbol={symbol}
              width="60"
              height="60"
              alt=""
              handlerLoad={(symbol) => loadArticleSticker(symbol, 60, img)}
            />
          </picture>
        ))}
      </div>
    </div>
  );
};

const PriceTemplate = ({ item }) => {
  const { t } = useTranslation("shop");

  const [cartState, cartAction] = useCartAction();
  const defaultJM = item.jm_pom.find(el => el.podstawowa === 1);
  const [selectedJM, setSelectedJM] = useState(defaultJM);
  const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });

  useEffect(() => {
    // console.log('currency', String(+item.cena * parseFloat(selectedJM.przelicz)), item.cena, parseFloat(selectedJM.przelicz));

    const jm = item.jm_pom.find(el => el.symbol_jmp === cartState.measure[item.symbol_art]);
    if (jm !== undefined) setSelectedJM(jm);

    setQuantity({ ...cartAction.getProduct({ item }) });
  }, [cartState.measure[item.symbol_art]]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center mb-0">
          {item.cena_n !== undefined && (
            <>
            <div className="mt-1 mr-2 text-gray-500">{t('Cena netto')}:</div>
              <div className="flex flex-row items-center justify-start flex-grow space-x-2" >
                {+item.cena_nom > 0 && Number.parseFloat(+item.cena_nom).toFixed(2) != +item.cena && (
                  <span className={`block text-xs text-red-400 leading-3 text-right items-right pr-8`} translate="0">
                    {numberFormatCurrency(+item.cena_nom, item.currency)}
                  </span>
                )}
                {+item?.base_price > 0 && roundTo(+item.base_price, 2) !== roundTo(item.cena, 2) && (
                  <span className='block text-sm leading-3 text-red-400 line-through' translate="0">
                    {numberFormatCurrency(+item.base_price * parseFloat(selectedJM.przelicz), item.currency)}
                  </span>
                )}
                <span className={`block text-xl md:whitespace-nowrap`} translate="0">
                {+item.cena ?
                  +item.cena !== 0 ? numberFormatCurrency((+item.cena) * parseFloat(selectedJM.przelicz), item.currency) : '-,--'
                  : numberFormatCurrency((+item.cena_n) * parseFloat(selectedJM.przelicz), item.currency)}
                  {(
                    <>&nbsp;/&nbsp;<span className="inline-block">
                      {selectedJM.symbol_jmp}
                    </span>
                    </>
                  )}
                </span>
              </div>
            </>
          )}
          </div>
      </div>
    </>
  );
};

const BarTemplate = ({ item }) => {
  const defaultJM = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
  if (defaultJM === undefined) return ''; // prevent reload
  const [state, cartAction] = useCartAction();
  const jmList = item.jm_pom
    .filter(el => el.visible == 1)
    .map(el => ({ key: el.symbol_jmp, value: el.nazwa_jm, sort: el.podstawowa }))
    .sort((a, b) => a.sort + b.sort)
  ;
  const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
  const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJM);

  const changeMeasure = (key) => {
    cartAction.setMeasure(item, key);
    setSelectedJM(item.jm_pom.find(el => el.symbol_jmp === key));
  };

  return (
    <div className="flex flex-col items-center gap-2 xs:items-start xs:flex-row">
        {(item.jm_pom.length > 1 || (selectedJM?.podstawowa != undefined && selectedJM?.podstawowa !== 1)) && (
          <div className={`flex flex-col leading-5 ${item.jm_pom.length > 1 ? 'pr-2' : ''}`}>
            {item.jm_pom.length > 1 && (
              <SSelect
                className='py-1 pl-2 pr-8 text-sm leading-5 border rounded-md h-9'
                value={jmList}
                defaultValue={selectedJM?.symbol_jmp || defaultJMP || defaultJM}
                handleChange={changeMeasure}
              />
            )}
            {selectedJM?.podstawowa != undefined && selectedJM?.podstawowa !== 1 && (
              <span className="block text-xs text-gray-400">
                {`1${selectedJM.symbol_jmp}`}
                &nbsp;=&nbsp;
                {selectedJM.przelicz}
                {item.symbol_jm}
              </span>
            )}
          </div>
        )}
    </div>
  )
  
}

const getArticleFiles = async (symbol) => {
  const resp = await api.getArticleFiles(symbol, 'Z');
  return resp?.data;
};

const CatalogArticleShow = () => {
  const globalState = useContext(GlobalStateContext);
  const params = useParams();
  const symbol = decodeURIComponent(params.symbol);
  // const globalContext = useContext(GlobalDispatchContext);

  const [article, setArticle] = useState(null);
  const [articleFiles, setArticleFiles] = useState([]);
  const [articleStickers, setArticleStickers] = useState(0);
  const [pathTree, setPathTree] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [nodeKey, setNodeKey] = useState(['']);

  const [loading, setLoading] = useState(false);

  const handleCategory = (categoryList) => {
    // if (Array.isArray(categoryList) && categoryList[0] != '') {
    //   globalContext.setCategory(categoryList[0]);
    // }
  };

  const [MenuTreeRender, menuTreePath] = useMenuTree({ handleCategory, newNode: nodeKey, catalog: true });

  useEffect(() => {
    setLoading(true);
    setArticle(null);
    setArticleFiles([]);
    // setArticleStickers(0);
  
      Promise.allSettled([getArticleFiles(symbol), getArticle(symbol), getArticleStickerCount(symbol)])
        .then(responses => {
          if (responses[0].value?.status === 0) {
            let files = parseJSON(responses[0].value.dane);
            if (!Array.isArray(files))
              files = [];
  
            setArticleFiles([ ...files ]);
          }
  
          if (responses[1].value?.status === 0) {
            const art = parseJSON(responses[1].value.dane);
            console.log('ARTICLE', art);
  
            if (art == undefined || (Array.isArray(art) && art.length === 0))
              setNotFound(true);
            else
              setArticle(art[0]);
          } else {
            setNotFound(true);
          }
  
          if (responses[2].value?.status === 0) {
            setArticleStickers(responses[2].value?.count);
          }
        }).finally(() => {
          setLoading(false);
        });
  }, [symbol, globalState.user]);

  useEffect(() => {
    if (article == null) return;

    const nodeKeyArticle = Array.isArray(article.node_key) ? article.node_key[0] : article.node_key;

    setNodeKey([nodeKeyArticle]);

    menuTreePath([nodeKeyArticle]).then(menuTree => {
      const tree = Object.fromEntries(
        Object.entries(menuTree)
          .map(([key, val]) => {
            return [key, '/catalog' + val];
          }),
      );

      // console.log("TREE", tree, nodeKeyArticle);
      setPathTree(Object.assign({}, { 'Katalog': '/catalog', ...tree }));
    });
  }, [article]);

  const countImages = () => {
    return articleFiles.length;
    // return articleFiles.reduce((cum, el) => cum + el.zdjecia.length, 0);
  }

  const countStickers = () => {
    return articleStickers;
  }

  return (
    <>
      <MenuSide>
        <div>
          {MenuTreeRender}
        </div>
      </MenuSide>
      <main className="w-full px-6 py-4">
        <div className="relative"> 
          {loading && <SpinnerFixed />}
          {(notFound) && <Alert content="Brak artykułu" color="yellow" />}
          {(article !== null && article !== undefined) && (
            <>
              <div className="flex flex-col items-center pb-2 mb-4 border border-t-0 border-l-0 border-r-0 border-gray-200 md:flex-row border-b-1">
                {Object.keys(pathTree).length > 0 && (
                  <div className="flex-grow mb-4 breadcrumps md:mb-0">
                    <BreadCrumbs path={pathTree} />
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center md:flex-row">
                <ImageGallery item={article} imageCount={countImages()} />
                <div className="flex flex-col mt-4 items-top justify-top md:ml-4 md:mt-0">
                  <h2 data-symbol={article.symbol_art} className={`${article?.extra_field != undefined ? "" :"mb-5"}`}>
                    {/* <LabelTag data={article.wyroznienia.find(Boolean)} /> */}
                    <div>
                      {parser.toReact(article.nazwa_art)}
                      {article?.symbol_art.length > 0 && (
                        <span className="block text-sm text-gray-500">
                          {article?.label_name || "Symbol"}: {article.symbol_art}
                        </span>
                      )}
                    </div>
                  </h2>
                  {article?.extra_field != undefined && (
                    <h3 data-extrafield={`${""+article.extra_field}`} className={`text-sm`}>
                      <span className="text-gray-500">{article.extra_field_name}:</span> {article.extra_field}
                    </h3>
                  )}
                  <div className="flex flex-row mb-1">
                    <PriceTemplate item={article} />
                  </div>
                  <div className="flex flex-row mb-5">
                    <BarTemplate item={article} />
                  </div>
                  {/* <div className="flex flex-row mb-3 empty:hidden">
                    <ArticleProperties item={article} />
                  </div> */}
                  <div className="flex flex-row mb-3 empty:hidden">
                    <StickerGallery symbol={article.symbol_art} imageCount={countStickers()} />
                  </div>
                </div>
              </div>
              {(article && <ArticleTabs item={article} files={articleFiles} />)}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default CatalogArticleShow;
