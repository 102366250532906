import { useRef, useCallback, useState, useEffect } from 'react';
import { hashCode, debounce } from '../../components/util';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import React from 'react';

const DivGrow = styled.div`
  display: grid;

  &::after {
    font-size:1rem;
    line-height:1.5rem;
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    word-break: break-all;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
  }

  & > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::after {
    padding: 0.60rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;


const TextareaNote = ({
  name,
  className,
  maxLength = 65000,
  defaultValue = "",
  isSaved = true,
  onChange = (data) => {},
  onSave = (save) => {},
}) => {
  const { t } = useTranslation("cart");
  const refNote = useRef();
  const [isSave, setIsSave] = useState(isSaved);

  const onTextareaGrow = useCallback( // for non multi
    debounce((first) => {
      if (first != true && isSave == true) {
        setIsSave(false);
        onSave(false);
      }
      // e.preventDefault();
      const note = refNote.current.value.substring(0, maxLength);
      refNote.current.parentNode.dataset.value = note;
    }, 50),
    [isSave]
  );

  const onClearNote = () => {
    setIsSave(false);
    onSave(false);
    refNote.current.value = "";
    onTextareaGrow();
  };

  const onRevertNote = () => {
    if (isSave == true) return false;

    setIsSave(true);
    onSave(true);
    refNote.current.value = defaultValue;
  };

  const onSaveNote = () => {
    if (isSave == true || refNote.current == undefined) return;
    setIsSave(true);
    typeof onChange == "function" && onChange(refNote.current.value);
    onSave(true);
  };

  useEffect(() => {
    onTextareaGrow(true);
  }, []);

  // useEffect(() => {
  //   if (isSaved == isSave) return;
  //   setIsSave(isSaved);
  // }, [isSaved]);

  return (
    <div className="relative flex flex-row w-auto md:max-w-408">
      <div className="flex flex-col flex-grow">
        <DivGrow className="text-sm">
          <textarea
            ref={refNote}
            onInput={() => onTextareaGrow()}
            //onBlur={onBlurSave}
            className={`${className} text-base`}
            name={name}
            maxLength={maxLength}
            defaultValue={defaultValue}
            key={hashCode(defaultValue || "")}
            data-cy="order-comment"
          />
        </DivGrow>
        <span className="text-xs text-gray-400">
          <Trans t={t} i18nKey={"_UpTo"} values={{ max: maxLength }}>
            {"maksymalnie {{max}} znaków"}
          </Trans>
        </span>
      </div>
      <div className="absolute right-0 flex flex-row m-px -top-3"> 
        <button
          type="button"
          onClick={onRevertNote}
          className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
          data-tooltip={t('przywróć uwagi')}
        >
          <svg className={`w-4 h-4 ${isSave == false ? 'text-red-500' : 'text-gray-500'} bg-white rounded-full`} viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.70714 3.29289C9.09766 3.68342 9.09766 4.31658 8.70714 4.70711L6.41424 
              7H14C17.866 7 21 10.134 21 14C21 17.866 17.866 21 14 21H8.15388C7.60159 21 7.15388 20.5523 7.15388 20C7.15388 19.4477 
              7.60159 19 8.15388 19H14C16.7615 19 19 16.7614 19 14C19 11.2386 16.7615 9 14 9H6.41424L8.70714 11.2929C9.09766 11.6834 
              9.09766 12.3166 8.70714 12.7071C8.31661 13.0976 7.68345 13.0976 7.29292 12.7071L2.58582 8L7.29292 3.29289C7.68345 
              2.90237 8.31661 2.90237 8.70714 3.29289Z"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={onClearNote}
          className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
          data-tooltip={t('wyczyść uwagi')}
        >
          <svg className={`w-4 h-4 text-black bg-white rounded-full`} viewBox="-1.5 -2.5 24 24" fill="currentColor">
            <path d='M12.728 12.728L8.485 8.485l-5.657 5.657 2.122 2.121a3 3 0 0 0 4.242 0l3.536-3.535zM11.284 17H14a1 1 0 0 1 0 2H3a1 1 0 0 1-.133-1.991l-1.453-1.453a2 2 0 0 1 0-2.828L12.728 1.414a2 2 0 0 1 2.828 0L19.8 5.657a2 2 0 0 1 0 2.828L11.284 17z'/>
          </svg>
        </button>
        <button
          type="button"
          onClick={onSaveNote}
          className="p-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
          data-tooltip={t('zapisz uwagi')}
        >
          <svg className={`"w-4 h-4 ${isSave == false ? 'bg-green-500' : 'bg-gray-500'} rounded-full`} viewBox="-180 -220 750 750" fill="currentColor">
            <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TextareaNote;
