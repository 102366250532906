import Dialog from './dialog';
import Button from './button';

export default function ForwardDialog(props) {
  const { open, onClose, onGoTo, title, children, textGoTo = "OK", textCancel = "Zamknij", showCancel = true, blockOK = false } = props;

  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="p-4 pb-6">
        <h2 className="pr-8 text-xl">{title}</h2>
        <div className="pt-5">{children}</div>
      </div>
      <div className="flex justify-end p-3 bg-gray-100 border-t border-gray-200 border-dotted rounded-b-lg">
        {showCancel && (
          <div className="p-1">
            <Button
              className="text-white bg-red-400"
              onClick={() => onClose()}
            >
              {textCancel}
            </Button>
          </div>
        )}
        <div className="p-1">
          <Button
            className={`${blockOK ? 'bg-gray-500 cursor-not-allowed' : ''}`}
            onClick={() => !blockOK && onGoTo()}
          >
            {textGoTo}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}