import { useState, useRef, useEffect, useMemo } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { useTranslation } from 'react-i18next';

import NormalDialog from '../../components/modal/normal';
import ConfirmDialog from '../../components/modal/index';
import useCartAction from '../../components/hook/cartaction';
import AlertDialog from '../../components/modal/AlertDialog';
import ButtonCounter from './buttoncounter';
import { fireWait } from '../util/index';

const CartCopy = ({resetMultiplier = -1}) => {
  const { t } = useTranslation("cart");

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [multipier, setMultipier] = useState(1);
  const [showInput, setShowInput] = useState(false);
  const [, setConfirmClear] = useState(0);

  const [cartState, cartAction] = useCartAction();

  const refList = useRef();
  const refCartInput = useRef();
  const cartName = 'Nowy koszyk';

  const refreshCartW = useMemo(() => fireWait(async () => {
    if (refCartInput.current)
      refCartInput.current.value = onGetCartName();
  }), []);

  const refreshCartZ = useMemo(() => fireWait(async () => {
    const typ = 'Z';
    const cart = cartState.cart.findAsTyp(typ);
    // console.log('refreshCartZ', cart);

    if (cart !== undefined) return;

    const name = `Nowy ${typ == 'Z' ? 'koszyk' : 'wzorzec'}`;
    await cartAction.newCart(name, typ);
  }), []);

  useEffect(() => {
    refreshCartZ();
  }, [cartState.cart, cartState.select]);

  useEffect(() => {
    refreshCartW();
  }, [cartState.cart.selectPattern]);

  const copyToCart = async () => {
    setLoading(true);
    console.log('CartCopy', multipier);

    const selectPattern = cartState.cart.selectPattern;
    const selectNormal = cartState.cart.selectNormal;

    const cartId = await cartAction.copyCart(selectPattern, selectNormal, multipier);

    if (cartId === false) {
      AlertDialog({
        title: "Ostrzeżenie",
        message: "Nie udało się skopiować do koszyka",
      });
    }

    if (cartId > 0) {
      const cartName = cartAction.getCartName(cartId);
      openModalForCart(cartName);
    }

    console.log('CartCopy cartId', cartId);
    setLoading(false);
  };

  const changeName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("CartCopy changeName");
  };

  const showList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("CartCopy showList");

    setIsOpen(!isOpen);

    setTimeout(() => {
      refList.current && refList.current.focus();
    }, 100);
  };

  const onHideList = () => {
    setIsOpen(false);
  };

  const onCartSelect = (cartId) => {
    console.log('CartCopy select');
    setIsOpen(false);
    cartAction.selectCart(cartId, 'Z');
    refCartInput.current.value = cartId > 0 ? cartAction.getCartName(cartId) : cartName;
  };

  const onCartNew = (e) => {
    e.stopPropagation();
    setIsOpen(false);

    const cartCount = 1 + cartState.cart.filterAsTyp('Z').length;
    const name = 'Nowy koszyk' + cartCount;
    const id = cartAction.newCart(name);

    if (id !== false && id !== undefined) {
      refCartInput.current.value = name;
      refCartInput.current.focus();
    }
  };

  const openModalForCart = (cartName = '') => {
    const content = `Skopiowano pozycje do koszyka`;
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    render(
      <NormalDialog
        title="Informacja"
        open={true}
        onClose={closeModal}
      >
        {content} <b>{cartName}</b>
      </NormalDialog>,
      container,
    );
  };

  const onGetCartName = () => {
    const select = cartState.cart.selectNormal;
    return cartAction.getCartName(select);
  };

  const onCartKey = (e) => {
    if (e.key === 'Enter') {
      onEditSave(e);
      e.target.blur();
    }

    if (e.key === 'Escape') {
      onEditDiscard(e);
      e.target.blur();
    }
  };

  const onShowEdit = (e) => {
    // console.log('onShowEdit');

    e.preventDefault();
    setShowInput(true);
    setIsOpen(false);
  };

  const onEditDiscard = (e) => {
    // console.log('click discard');

    e.preventDefault();
    e.stopPropagation();

    setShowInput(false);
    refCartInput.current.value = onGetCartName();
  };

  const onEditSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const select = cartState.cart.selectNormal;
    console.log('cartselect onEditSave', refCartInput.current.value, select);
    cartAction.setCart(refCartInput.current.value, select, 'Z');

    setShowInput(false);
  };

  const onBlurSaveOrDiscard = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const cartDelete = (cartId) => {
    cartAction.delCart(cartId, 'Z');

    // console.log("DEL after", cartState.cart);

    const select = cartState.cart.selectNormal;
    if (select === cartId) {
      const cartIdNew = cartAction.selectCartFirst('Z');
      refCartInput.current.value = cartId != false ? cartAction.getCartName(cartIdNew) : cartName;
    }
  };

  const openModal = (cartId) => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      setConfirmClear(0);
      unmountComponentAtNode(container);
    };

    render(
      <ConfirmDialog
        title={"Usunięcie koszyka"}
        open={true}
        onClose={closeModal}
        onConfirm={() => cartDelete(cartId)}
      >
        Czy na pewno usunąć koszyk <b>{cartAction.getCartName(cartId)}</b> z pozycjami?
      </ConfirmDialog>,
      container,
    );
  };

  const onCartDelete = (e, id) => {
    console.log('onCartDelete', id);
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);

    if (!cartAction.isCartEmpty(id)) {
      openModal(id);
    } else {
      cartDelete(id);
    }
  };

  return (
    <>
      <div className="flex-row items-center hidden mx-2 md:flex">
        <span className="mr-4">×</span>
        <ButtonCounter onChange={(val) => setMultipier(parseInt(val, 10))} handleReset={resetMultiplier} />
      </div>
      <div className="relative z-20">
        <button
          type="button"
          className={`${1 === 0 ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-primary hover:bg-gray-800 '} pr-px py-px rounded text-sm flex flex-row items-center`}
        >
          <svg className={`${loading ? '' : 'hidden'} animate-spin ml-3 h-5 w-5 text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <span
            className="px-3 text-white"
            onClick={copyToCart}
          >
            {t('Kopiuj\u00A0do')}:
          </span>
          <span className="relative flex flex-row pr-10 bg-white rounded-r">
            <input
              ref={refCartInput}
              type="text"
              className="text-sm font-normal bg-transparent border-0 max-w-7rem cartcopy-input focus:ring-0"
              defaultValue={onGetCartName}
              onClick={changeName}
              onFocus={onShowEdit}
              onKeyUp={onCartKey}
            />
            <a
              href={void(0)}
              className="absolute inset-y-0 right-0 z-20 flex items-center px-2 border-l border-dashed"
              onClick={showList}
            >
              <svg className="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
              </svg>
            </a>
            <span className={`${showInput === true ? '' : 'hidden'} bg-white rounded absolute inset-y-0 right-0 flex items-center px-2 z-20`}>
              <a href={void(0)} onBlur={onBlurSaveOrDiscard} onClick={onEditDiscard} className="block w-6 h-6 mr-1 text-white border-4 border-transparent border-opacity-0">
                <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
                  <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
                </svg>
              </a>
              <a href={void(0)} onBlur={onBlurSaveOrDiscard} onClick={onEditSave} className="block w-6 h-6 text-white border-4 border-transparent border-opacity-0" style={{ marginRight: '-3px' }}>
                <svg className="w-4 h-4 bg-green-500 rounded-full" viewBox="-180 -220 750 750" fill="currentColor">
                  <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                </svg>
              </a>
            </span>
          </span>
        </button>
        <div
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        >
          <ul
            role="listbox"
            aria-labelledby="state-cart-label"
            aria-activedescendant="state-cart-0"
            className="py-1 overflow-auto text-sm rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {cartState.cart.entries().map(([, val]) =>
              (val.typ === 'Z') && (
              <li key={`cart_choose_${val.id}`} id="state-cart-0" role="option" aria-selected="false" className="relative text-gray-700 cursor-default select-none hover:text-white hover:bg-primary">
                <button
                  type="button"
                  onMouseDown={() => onCartSelect(val.id)}
                  className="w-full py-2 pl-3 pr-10 outline-none"
                >
                  <div className="flex items-center content-center">
                    {/* <img className="flex-shrink-0 w-5 h-5 rounded-full" src="" alt="" /> */}
                    <span className="block -mt-px text-sm font-normal truncate">
                      {val.name}
                    </span>
                  </div>
                  <span className={`${cartState.cart.selectNormal == val.id ? 'flex' : 'hidden'} absolute inset-y-0 right-0 items-center pr-3`}>
                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="4 1 20 20" fill="currentColor" aria-hidden="true">
                      <path clipRule="evenodd" fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                    </svg>
                  </span>
                    <a
                      href={void(0)}
                      onMouseDown={(e) => onCartDelete(e, val.id)}
                      className={`absolute inset-y-0 right-px flex items-center pr-2.5 text-red-500`}
                      title={val.id}
                    >
                      {/* ICON TRASH */}
                      <span className="p-px bg-white rounded-full">
                        <svg className="w-4 h-4 text-red-600 bg-white border border-white rounded-full" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5 15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z" />
                          <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
                        </svg>
                      </span>
                    </a>
                </button>
              </li>
            ))}
            <li id="state-cart-0" role="option" aria-selected="false" className="relative border-t cursor-default select-none text-primary hover:text-white hover:bg-primary">
              <button
                type="button"
                onMouseDown={onCartNew}
                className="w-full py-2 pl-3 pr-6 outline-none "
              >
                <div className="flex items-center content-center mr-3">
                  <span className="block -mt-px text-sm font-normal truncate">
                    {cartName}
                  </span>
                </div>
                <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg className="w-4 h-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm112 277.332031h-90.667969v90.667969c0 11.777344-9.554687 21.332031-21.332031 21.332031s-21.332031-9.554687-21.332031-21.332031v-90.667969h-90.667969c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h90.667969v-90.667969c0-11.777344 9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031v90.667969h90.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" />
                  </svg>
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CartCopy;
