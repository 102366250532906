
import { useRef, useState, useEffect } from 'react';

const useStatePrev = (defValue) => {
  const [value, setValue] = useState(defValue);
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref.current || defValue];
};

export default useStatePrev;
