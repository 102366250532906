import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';

import { humanFileSize, parseJSON } from '../../components/util/index';
import api from '../../components/service/api';

import AlertDialog from '../../components/modal/AlertDialog';
import Tooltip from '../../components/tooltip';
import { useTranslation } from 'react-i18next';

const Input = styled.input``;
// const Input = styled.input`
//   left:0;
//   animation: marquee 5s linear infinite;

//   @keyframes marquee {
//     0% { left: 0; }
//     50% { left: -20%; }
//     100% { left: 0; }
//   }
// `;

const Button = styled.button`
  &:hover div.tooltip-hover {
    display:block;
  }
`;

const uploadFileCart = async (formData) => {
  const resp = await api.uploadFileCart(formData);
  return resp?.data;
}

const deleteFileCart = async (id) => {
  const resp = await api.deleteFileCart(id);
  return resp?.data;
}

const listFileCart = async (id) => {
  const resp = await api.listFileCart(id);
  return resp?.data;
}

type FileTarget = {
  name: string,
  size: number,
};

const InputFile = ({
  name,
  defaultValue = 0, 
  className='', 
  onChange = (data) => {},
  defaultMessage = '-----'
}) => {
  const { t } = useTranslation("cart");

  const [loading, setLoading] = useState<Boolean>(false);
  const [file, setFile] = useState<FileTarget>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  let isMounted = false;

  const handleUploadClick = () => {
    if (!file) {
      inputRef.current?.click();
      return;
    }

    if (inputRef.current)
      inputRef.current.value = null;
    
    setFile(undefined);
    setLoading(true);
    deleteFileCart(defaultValue)
      .then(data => {
        console.log("DELETE", data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setLoading(true);
    const fileTarget = e.target.files[0];
    const maxAllowedSize = 5 * 1024 * 1024;
    const fileExt = [
      "text/plain",
      "image/jpeg",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    console.log("FILE", fileTarget);

    if (fileTarget?.size == undefined || fileTarget?.size == 0 || fileTarget?.size > maxAllowedSize) {
      setLoading(false);
      setFile(undefined);
      AlertDialog({
        title: 'Informacja o załączniku',
        message: 'Nieprawidłowy rozmiar pliku. \nMaksymalny rozmiar to: 5MB',
        color: "yellow",
      });
      return;
    }
    
    if (!fileExt.includes(fileTarget?.type)) {
      setLoading(false);
      setFile(undefined);
      AlertDialog({
        title: 'Informacja o załączniku',
        message: "Nieprawidłowy typ pliku. \nDozwolone o rozszerzeniu: .pdf, .txt, .doc, .docx, .jpg",
        color: "yellow",
      });
      return;
    }

    setFile({ name: fileTarget.name, size: fileTarget.size}); 
    
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("id", ""+defaultValue);

    uploadFileCart(formData)
      .then(data => {
        if (data?.status != 0) {
          setFile(undefined);
        }
        console.log("UPLOAD", data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    setFile(undefined);

    if (defaultValue == 0) {
      isMounted && setLoading(false);
      return;
    }

    listFileCart(defaultValue)
      .then(data => {
        if (data?.status != 0) {
          return false;
        }

        const fileData = parseJSON(data?.dane);
        if (!fileData || fileData.size == 0) {
          return false;
        }

        if (isMounted) {
          setFile({ name: fileData.name, size: fileData.size });
        }
      }).finally(() => {
        isMounted && setLoading(false);
      });
    
    return () => {
      isMounted = false;
    }
  }, [defaultValue]);
  
  return (
    <>
      <div className={`relative max-w-sm`}>
        <Button
          type="button"
          name={"upload-file_"+name}
          // aria-haspopup="listbox"
          // aria-labelledby="listbox-label"
          className={`bg-primary shadow-sm  hover:bg-gray-800  pr-px py-px rounded text-sm flex flex-row items-center`}
          onClick={handleUploadClick}
          disabled={loading ? true : false}
        >
          {loading && (
            <a href={void(0)} className={`px-3`} data-title='ładowanie...'> 
              <svg className="w-5 h-5 text-white animate-spin" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </a>
          )}
          {!loading && !file && (
            <a href={void(0)} className='px-3' data-title='dodaj załącznik'>
              <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 32 32">
                <path d="M15.5 29.5c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.938 15.938c0-0.552-0.448-1-1-1h-4v-4c0-0.552-0.447-1-1-1h-1c-0.553 0-1 0.448-1 
                  1v4h-4c-0.553 0-1 0.448-1 1v1c0 0.553 0.447 1 1 1h4v4c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1v-4h4c0.552 0 1-0.447 1-1v-1z"
                />
              </svg>
            </a>
          )}
          {!loading && file && (
            <a href={void(0)} className='px-3' data-title='usuń załącznik' >
              <svg className="w-5 h-5 text-red-600 bg-white border-2 border-white rounded-full" viewBox="0 0 24 24" fill="currentColor">
                <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5 15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z" />
                <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
              </svg>
              {/* <svg className="w-5 h-5 text-red-500 " viewBox="0 0 32 32" fill="currentColor" 
                // style={{ width: "1.5em", height: "1.5em" }}
              >
                <path 
                  stroke="white" strokeWidth="2.8"
                  d="M15.5 3.5c-7.18 0-13 5.82-13 13s5.82 13 13 13c7.18 0 13-5.82 13-13s-5.82-13-13-13zM22 16.875c0 0.553-0.448 1-1 
                  1h-11c-0.553 0-1-0.447-1-1v-1c0-0.552 0.447-1 1-1h11c0.552 0 1 0.448 1 1v1z"
                />
              </svg> */}
            </a>
          )}
          <span className="relative flex flex-row overflow-hidden bg-white rounded-r max-w-200">
            <Input 
              placeholder={defaultMessage}
              type="text" 
              className={`${file ? 'top-1.5 relative ' : ''} text-sm font-normal bg-transparent border-0 cartcopy-input focus:ring-0 cursor-default`} 
              value={file ? file.name : ''} 
              disabled
            />
            {file && (
              <>
                <span className='absolute inset-y-0 text-xs text-gray-400 left-3'>
                  {t('rozmiar')}: <b>{humanFileSize(file.size, true, 2)}</b>
                </span>
                <span className="absolute inset-y-0 left-3">

                </span>
              </>
            )}
            {/* <a className="absolute inset-y-0 right-0 z-10 flex items-center px-2 border-l border-dashed">
              <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none" style={{ stroke: "var(--primary)"}}>
                <path 
                  d="M13 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V9M13 3L19 9M13 3V8C13 8.55228 13.4477 9 14 9H19" 
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                />
              </svg>
            </a> */}
          </span>
          <Tooltip
            className="absolute hidden -top-2 -left-2 tooltip-hover"
            content={(
              <div className="min-w-200">
                {t('Plik o rozmiarze')}:<br /><b>max. 5MB</b><br />
                {t('Dozwolone rozszerzenia')}:<br /><b>.txt</b> <b>.pdf</b> <b>.doc</b> <b>.docx</b> <b>.jpg</b>
              </div>
            )}
          >          
            <div className='z-10 w-4 h-4 text-blue-500 hover:text-primary'>
              <svg viewBox="0 0 512 512" fill='currentColor'>
                <circle cx="50%" cy="50%" r="50%" fill="white" />
                <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 
                  90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 
                  16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 
                  33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 
                  83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 
                  46-46c0-25.365-20.635-46-46-46z"
                />
              </svg>
            </div>
          </Tooltip>
        </Button>
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          accept=".pdf,.txt,.doc,.docx,.jpg"
          style={{ display: 'none' }}
          defaultValue={""}
        />
      </div>
    </>
  );
};

export default InputFile;
