import Dialog from './dialog';
import Button from './button';
import Translate from "../translate/Translate";

export default function NormalDialog(props) {
  const { open, onClose, title, children, textBtn, showButton = true } = props;

  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="p-4 pb-6">
        <h2 className="text-xl"><Translate ns="dialog" value={title} /></h2>
        <div className="pt-5">{children}</div>
      </div>
      {showButton && (
        <div className="flex justify-end p-3 bg-gray-100 rounded-b-lg">
          <div className="p-1">
            <Button
              className="text-white bg-red-400"
              onClick={() => {
                onClose();
              }}
            >
              <Translate ns="dialog" value={textBtn ?? "Zamknij"} />
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}